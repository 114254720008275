import style from "./style.scss";

const ZennichiIndex = () => (
  <div className={style.ZennichiIndex}>
    <img
      className={style.ZennichiIndexImage}
      src="/assets/zennichi/rabby-chan_image2.png"
      alt="ラビーちゃん"
    />
    <div className={style.ZennichiIndexTextContainer}>
      <div className={style.ZennichiIndexCaption}>SPECIAL PRICE</div>
      <div className={style.ZennichiIndexText}>
        <span className={style.ZennichiIndexTextSmall}>全日会員様</span>
        <br />
        特別価格
      </div>
    </div>
  </div>
);

export default ZennichiIndex;
