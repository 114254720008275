import { h } from "preact";
import ZennichiGlobalNavigation from "../../../components/ZennichiGlobalNavigation";
import style from "./style.scss";
import { functionListIKC } from "../../../util/functionListIKC";
import ZennichiContactForm from "../../../components/ZennichiContactForm";

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <img className={style.PrimaryButtonIcon} src={props.iconSrc} />
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function ExplanationCard(props) {
  return (
    <div className={style.ExplanationCardWrapper}>
      <div className={style.ExplanationCardImageWrapper}>
        <img alt="" src={props.imgSrc} className={style.ExplanationCardImage} />
      </div>
      <div className={style.ExplanationCardTextWrapper}>
        <h3 className={style.ExplanationCardTitle}>{props.title}</h3>
        <p className={style.ExplanationCardDescription}>{props.description}</p>
      </div>
    </div>
  );
}

function FunctionLinkCard(props) {
  return (
    <a native href={props.href} className={style.FunctionLinkCardWrapper}>
      <img alt="" src={props.iconSrc} className={style.FunctionLinkCardImage} />
      <h3 className={style.FunctionLinkCardTitle}>{props.title}</h3>
    </a>
  );
}

function FeatureElement(props) {
  return (
    <div className={style.FeatureContainer}>
      <div className={style.FeatureDescriptionContainer}>
        <p className={style.FeatureIndex}>{props.index}</p>
        <h3 className={style.FeatureTitle}>{props.title}</h3>
        <p className={style.FeatureDescription}>{props.description}</p>
      </div>
      <div className={style.FeatureImageContainer}>
        <img src={props.imgSrc} alt="" className={style.FeatureImage} />
      </div>
    </div>
  );
}

const ZennichiItandiKanriCloud = () => (
  <>
    <ZennichiGlobalNavigation />
    <section className={style.HeroSection}>
      <div className={style.HeroWrapper}>
        <div className={style.HeroDescriptionContainer}>
          <p className={style.HeroDescriptionZennichiText}>
            【全日会員様向けプロダクトご紹介ページ】
          </p>
          <p className={style.HeroDescriptionLeadCopy}>
            イタンジの賃貸管理システム
          </p>
          <img
            className={style.HeroDescriptionLogo}
            src="/assets/service_logo/itandi_kanri_cloud_white.svg"
            alt="イタンジ管理クラウドロゴ"
          />
          <h1 className={style.HeroDescriptionTitle}>
            賃貸管理業務を一括して効率化できるシステム
          </h1>
          <ol className={style.HeroDescriptionFunction}>
            <li className={style.HeroDescriptionFunctionItem}>精算管理</li>
            <li className={style.HeroDescriptionFunctionItem}>物件管理</li>
            <li className={style.HeroDescriptionFunctionItem}>入居者管理</li>
            <li className={style.HeroDescriptionFunctionItem}>家主管理</li>
            <li className={style.HeroDescriptionFunctionItem}>修繕管理</li>
          </ol>
          <div className={style.HeroDescriptionButtonList}>
            <div className={style.HeroDescriptionButtonContainer}>
              <PrimaryButton
                iconSrc="/assets/crm/icon/mail_white.svg"
                text="お問い合わせ"
                href="#contact"
              />
            </div>
          </div>
        </div>
        <div className={style.HeroVisualContainer}>
          <img
            className={style.HeroVisualImage}
            src="/assets/itandi_kanri_cloud/IDK_kv_function.png"
            alt=""
          />
          <img
            className={style.HeroVisualImageBG}
            src="/assets/itandi_kanri_cloud/IKC_PC_mockup.png"
            alt=""
          />
        </div>
        <div className={style.HeroDescriptionButtonListSP}>
          <div className={style.HeroDescriptionButtonContainer}>
            <PrimaryButton
              iconSrc="/assets/crm/icon/mail_white.svg"
              text="お問い合わせ"
              href="#contact"
            />
          </div>
        </div>
      </div>
    </section>
    <section />

    <section className={style.AboutSection}>
      <div className={style.AboutWrapper}>
        <h3 className={style.AboutTitle}>
          <span className={style.TitleBr}>
            <span>管理業務の</span>
            <span>デジタル化を実現</span>
          </span>
        </h3>
        <p className={style.AboutDescription}>
          {`管理業務をデジタル化することにより、あらゆる手間やコストが削減できます。`}
        </p>
        <div>
          <img
            className={style.AboutImageBeforePC}
            src="/assets/itandi_kanri_cloud/about_before.png"
            alt="手間の多く残る従来の管理業務"
            title={`手間の多く残る従来の管理業務`}
            subTitle="ABOUT_BEFORE"
          />
          <img
            className={style.AboutImageBeforeTAB}
            src="/assets/itandi_kanri_cloud/about_before_tablet.png"
            alt="手間の多く残る従来の管理業務"
            title={`手間の多く残る従来の管理業務`}
            subTitle="ABOUT_BEFORE"
          />
          <img
            className={style.AboutImageBeforeSP}
            src="/assets/itandi_kanri_cloud/about_before_sp.png"
            alt="手間の多く残る従来の管理業務"
            title={`手間の多く残る従来の管理業務`}
            subTitle="ABOUT_BEFORE"
          />
          <img
            className={style.AboutImageArrow}
            src="/assets/itandi_kanri_cloud/arrow.svg"
            alt="矢印"
            title={`管理業務のデジタル化を実現`}
            subTitle="ABOUT_ARROW"
          />
          <img
            className={style.AboutImageAfter}
            src="/assets/itandi_kanri_cloud/about_after.png"
            alt="デジタルに移行して効率化"
            title={`デジタルに移行して効率化`}
            subTitle="ABOUT_AFTER"
          />
        </div>
      </div>
    </section>

    <section className={style.FunctionSection}>
      <div className={style.FunctionWrapper}>
        <h3 className={style.FunctionTitle}>
          <span className={style.TitleBr}>
            <span>オールインワンの</span>
            <span>機能を搭載</span>
          </span>
        </h3>
        <div className={style.FunctionListIKC}>
          {functionListIKC.map((functionItem) => (
            <FunctionLinkCard
              title={functionItem.titleTwoLines}
              iconSrc={functionItem.iconOnCirclesSrc}
            />
          ))}
        </div>
      </div>
    </section>
    <section className={style.FeatureSection}>
      <div className={style.FeatureWrapper}>
        <div className={style.FeatureWrapper}>
          <h3 className={style.FeatureMainTitle}>
            <span className={style.TitleBr}>
              <span>イタンジ管理クラウドで</span>
              <span>できること</span>
            </span>
          </h3>
          <p className={style.FeatureMainDescription}>
            普段の管理業務で必要不可欠な、入金管理や内装工事関連、
            <br />
            入居者・オーナーとのコミュニケーションツールなど、管理業務がすべて入っています。
          </p>
          <div className={style.FeatureCardContainer}>
            <FeatureElement
              index={`POINT 01`}
              title={`入金消込/オーナー送金/請求も一元管理`}
              description="入手金・未収金管理全般を一元管理できます。またファームバンキングやネットバンキングの利用も対応し、入金消込業務の自動化、総合振込等も可能です。"
              imgSrc="/assets/itandi_kanri_cloud/IKC_feature01.png"
              alt="入金消込/オーナー送金/請求も一元管理"
            />
            <FeatureElement
              index={`POINT 02`}
              title={`更新/退去手続きと入居者様とのやりとりがWebで完結`}
              description="更新契約書類の出力はボタン1つで、更新は電子契約で郵送代を0円にできます。また解約受付やクレーム受付、更新通知等もWeb上のチャットでやり取り可能です。"
              imgSrc="/assets/itandi_kanri_cloud/IKC_feature02.png"
              alt="更新/退去手続きと入居者様とのやりとりがWebで完結"
            />
            <FeatureElement
              index={`POINT 03`}
              title={`Webで立会依頼や工程管理ができ、確認作業を大幅削減`}
              description="立会依頼や工事発注、工事日程の確認連絡など、これまでメールや電話での確認作業をWeb化することで大幅に削減できます。また書類や写真の自動保存も可能です。"
              imgSrc="/assets/itandi_kanri_cloud/IKC_feature03.png"
              alt="Webで立会依頼や工程管理ができ、確認作業を大幅削減"
            />
            <FeatureElement
              index={`POINT 04`}
              title={`物件・家主情報を一元管理して、楽々契約書・帳票出力`}
              description="建物・部屋・家主情報を一元的に管理することで、契約書や帳票の出力時に情報連携され転記作業やミスを大幅に軽減することができます。"
              imgSrc="/assets/itandi_kanri_cloud/IKC_feature04.png"
              alt="物件・家主情報を一元管理して、楽々契約書・帳票出力"
            />
          </div>
        </div>
      </div>
    </section>
    <section className={style.LinkageSection}>
      <div className={style.LinkageWrapper}>
        <h3 className={style.LinkageTitle}>
          <span className={style.TitleBr}>
            <span>イタンジ既存サービス</span>
            <span>との相関性</span>
          </span>
        </h3>
        <p className={style.LinkageDescription}>
          イタンジが提供しているのすべてのサービスと連携が可能。
          <br />{" "}
          連携することで管理業務の効率化が加速、さらに仲介業務まで一気通貫で効率化することができます。
        </p>
        <img
          className={style.LinkageImagePC}
          src="/assets/itandi_kanri_cloud/link_age.png"
          alt="既存サービス連携によりさらなる業務効率化が可能"
          title={`イタンジ既存サービスとの相関性`}
          subTitle="LINKAGE"
          color="light"
        />
        <img
          className={style.LinkageImageSP}
          src="/assets/itandi_kanri_cloud/link_age_sp.png"
          alt="既存サービス連携によりさらなる業務効率化が可能"
          title={`イタンジ既存サービスとの相関性`}
          subTitle="LINKAGE"
          color="light"
        />
        <p className={style.LinkageAddIkcDescription}>
          イタンジ管理クラウドの登場により
          <br />
          すべての管理業務の効率化が可能に
        </p>
        <img
          className={style.LinkageAddIkcImagePC}
          src="/assets/itandi_kanri_cloud/add_to_kanri_IKC.png"
          alt="イタンジ管理クラウド追加ですべての管理業務が効率化"
        />
        <img
          className={style.LinkageAddIkcImageSP}
          src="/assets/itandi_kanri_cloud/add_to_kanri_IKC_sp.png"
          alt="イタンジ管理クラウド追加ですべての管理業務が効率化"
        />
      </div>
    </section>
    <section className={style.InstallationSection}>
      <div className={style.InstallationSectionWrapper}>
        <h3 className={style.InstallationTitle}>
          <span className={style.TitleBr}>
            <span>導入後も</span>
            <span>しっかりサポート</span>
          </span>
        </h3>
        <div className={style.InstallationWrapper}>
          <div className={style.InstallationCardContainer}>
            <ExplanationCard
              title={`導入レクチャー\n＆安心のサポート`}
              description="パソコンに不慣れな現場でもサポートチームがしっかりレクチャー＆サポートいたします。"
              imgSrc="/assets/itandi_kanri_cloud/customer_support.svg"
              alt=""
            />
          </div>
          <div className={style.InstallationCardContainer}>
            <ExplanationCard
              title={`定期的な\nフォローアップ`}
              description="業績状況に合わせて、他社様で成功した活用方法や新機能の使い方など定期的にフォローいたします。"
              imgSrc="/assets/itandi_kanri_cloud/calendar_clock.svg"
              alt=""
            />
          </div>
          <div className={style.InstallationCardContainer}>
            <ExplanationCard
              title={`時流に対応した\n機能追加`}
              description="クラウド型サービスだからこそ法改正等などに応じて柔軟に機能追加を行うことができます。"
              imgSrc="/assets/itandi_kanri_cloud/add_function.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <div className={style.CTAAreaIKCWrapper}>
      <div className={style.CTAAreaIKCContainer}>
        <h3 className={style.CTAAreaIKCHeading}>
          イタンジ管理クラウドで
          <br />
          <span className={style.TitleBr}>
            <span>賃貸管理業務を</span>
            <span>効率化しませんか？</span>
          </span>
        </h3>
        <a
          native
          className={style.CTAAreaIKCPrimaryButtonContainer}
          href="#contact"
        >
          <img
            className={style.CTAAreaIKCPrimaryButtonIcon}
            src="/assets/crm/icon/mail_white.svg"
          />
          <p className={style.CTAAreaIKCPrimaryButtonText}>お問い合わせ</p>
        </a>
      </div>
    </div>
    <section className="contact-section" id="contact">
      <div className="container-width">
        <div className="contact-container" id="contact">
          <h3 className={style.AboutTitle}>
            <span className={style.TitleBr}>
              <span>お問い合わせ</span>
            </span>
          </h3>
          <p className={style.ContactDescription}>
            お問い合わせを多数いただいており、お打ち合わせまでお時間いただくことがございます。
          </p>
          {/* <h3 className={style.ContactTitle}>お問い合わせ</h3> */}
          <div className="contact-form">
            <ZennichiContactForm />
          </div>
        </div>
      </div>
    </section>

    <section className={style.CTASection}>
      <div className={style.CTAAreaFollow}>
        <div className={style.CTAAreaButtonWrapper}>
          <PrimaryButton
            iconSrc="/assets/crm/icon/mail_white.svg"
            text="お問い合わせ"
            href="#contact"
          />
        </div>
      </div>
    </section>
  </>
);

export default ZennichiItandiKanriCloud;
