import { h } from "preact";
import Match from "preact-router/match";
import style from "./style.scss";
import itandiLogo from "/assets/logo_itandi.svg";
import ZennichiLogo from "/assets/zennichi/zennichi_logo.svg";

const ZennichiHeader = () => (
  <header class={style.Header}>
    <a native class={style.HeaderLogoContainer} href="/zennichi/itandi-bb">
      <img src={itandiLogo} alt="ITANDI" class={style.HeaderLogoImage} />
      <img
        src={ZennichiLogo}
        alt="Zennichi"
        class={style.HeaderLogoImageRight}
      />
    </a>
    <nav class={style.HeaderButtonsContainer}>
      <div class={style.HeaderSecondaryButton}>
        <a native class={style.SecondaryButton} href="#contact">
          <span native class={style.ButtonTextPC}>
            資料請求・
          </span>
          お問い合わせ
        </a>
      </div>
      <Match path="/crm/:remaining_path*">
        {({ matches }) => (
          <a
            native
            class={style.PrimaryButton}
            href={
              matches
                ? `https://${NOMADCLOUD_HOST}/follow_up_client/top`
                : `https://${ITANDI_BB_HOST}/top`
            }
          >
            ログイン
          </a>
        )}
      </Match>
    </nav>
  </header>
);

export default ZennichiHeader;
