import { h } from "preact";
import ZennichiGlobalNavigation from "../../../components/ZennichiGlobalNavigation";
import ZennichiContactForm from "../../../components/ZennichiContactForm";
import InterviewCard from "../../../components/InterviewCard";
import useAnchorHandle from "../../../util/useAnchorHandle";
import style from "./style.scss";
import classnames from "classnames";

function OutlinedButton(props) {
  return (
    <a
      native
      className={classnames(
        style.OutlinedButtonContainer,
        props.serviceName === "IKC" && style.OutlinedButtonContainerIKC,
        props.serviceName === "NK" && style.OutlinedButtonContainerNK,
        props.serviceName === "MU" && style.OutlinedButtonContainerMU,
        props.serviceName === "DK" && style.OutlinedButtonContainerDK,
        props.serviceName === "KT" && style.OutlinedButtonContainerKT,
        props.serviceName === "BK" && style.OutlinedButtonContainerBK,
        props.serviceName === "NSK" && style.OutlinedButtonContainerNSK,
        props.serviceName === "RPA" && style.OutlinedButtonContainerRPA,
        props.serviceName === "NC" && style.OutlinedButtonContainerNC
      )}
      target={(props.target === "_blank" && "_blank") || ""}
      rel={(props.target === "_blank" && "noopener noreferrer") || ""}
      href={props.href}
    >
      {props.text}
    </a>
  );
}
function OptionkunItemLogoAndTitle(props) {
  return (
    <>
      <a native className={style.OptionkunItemLogo} href={props.href}>
        <img className={style.OptionkunItemLogoImage} alt src={props.logoSrc} />
      </a>
      <h4
        className={classnames(
          style.OptionkunItemTitle,
          props.serviceName === "IKC" && style.OptionkunItemTitleIKC,
          props.serviceName === "NK" && style.OptionkunItemTitleNK,
          props.serviceName === "MU" && style.OptionkunItemTitleMU,
          props.serviceName === "DK" && style.OptionkunItemTitleDK,
          props.serviceName === "KT" && style.OptionkunItemTitleKT,
          props.serviceName === "BK" && style.OptionkunItemTitleBK,
          props.serviceName === "NSK" && style.OptionkunItemTitleNSK,
          props.serviceName === "RPA" && style.OptionkunItemTitleRPA,
          props.serviceName === "NC" && style.OptionkunItemTitleNC
        )}
      >
        <small>{props.title}</small>
        {props.description}
      </h4>
    </>
  );
}

function OptionkunItemLogoAndTitleNSK(props) {
  return (
    <>
      <a native className={style.OptionkunItemLogoNSK} href={props.href}>
        <img
          className={style.OptionkunItemLogoImageNSK}
          alt
          src={props.logoSrc}
        />
      </a>
      <h4
        className={classnames(
          style.OptionkunItemTitle,
          props.serviceName === "NSK" && style.OptionkunItemTitleNSK
        )}
      >
        <small>{props.title}</small>
        {props.description}
      </h4>
    </>
  );
}

function HeroFeatureListItem(props) {
  return (
    <li
      className={classnames(
        style.HeroFeatureListItem,
        props.serviceName === "IKC" && style.HeroFeatureListItemIKC,
        props.serviceName === "NK" && style.HeroFeatureListItemNK,
        props.serviceName === "MU" && style.HeroFeatureListItemMU,
        props.serviceName === "DK" && style.HeroFeatureListItemDK,
        props.serviceName === "KT" && style.HeroFeatureListItemKT,
        props.serviceName === "BK" && style.HeroFeatureListItemBK,
        props.serviceName === "NSK" && style.HeroFeatureListItemNSK,
        props.serviceName === "RPA" && style.HeroFeatureListItemRPA,
        props.serviceName === "NC" && style.HeroFeatureListItemNC
      )}
    >
      <a native className={style.HeroFeatureListItemLink} href={props.href}>
        {props.title}
      </a>
    </li>
  );
}

function OptionkunItemLayout(props) {
  return (
    <div className={style.OptionkunItemLayoutContainer}>
      <div className={style.OptionkunItemLayoutText}>
        <p className={style.OptionkunItemLayoutHeadline}>{props.heading}</p>
        <p className={style.OptionkunItemLayoutBodycopy}>{props.description}</p>
      </div>
      <div className={style.OptionkunItemLayoutImageContainer}>
        <img
          className={style.OptionkunItemLayoutImage}
          alt
          src={props.imgSrc}
        />
      </div>
    </div>
  );
}

function AboutFeatureCard(props) {
  return (
    <li
      className={classnames(
        style.AboutFeatureCardContainer,
        props.serviceName === "IKC" && style.AboutFeatureCardContainerIKC,
        props.serviceName === "NK" && style.AboutFeatureCardContainerNK,
        props.serviceName === "MU" && style.AboutFeatureCardContainerMU,
        props.serviceName === "DK" && style.AboutFeatureCardContainerDK,
        props.serviceName === "KT" && style.AboutFeatureCardContainerKT,
        props.serviceName === "BK" && style.AboutFeatureCardContainerBK,
        props.serviceName === "NSK" && style.AboutFeatureCardContainerNSK,
        props.serviceName === "RPA" && style.AboutFeatureCardContainerRPA,
        props.serviceName === "NC" && style.AboutFeatureCardContainerNC
      )}
    >
      <a native className={style.AboutFeatureCardLink} href={props.href}>
        <h3 className={style.AboutFeatureCardTitle}>{props.title}</h3>
        <p className={style.AboutFeatureCardCopy}>{props.copy}</p>
        <div className={style.AboutFeatureCardLogo}>
          <img alt={props.imgAlt} src={props.imgSrc} />
        </div>
        <span className={style.AboutFeatureCardButton}>詳細</span>
      </a>
    </li>
  );
}

const ZennichiItandiBB = () => {
  useAnchorHandle();
  return (
    <div className={style.ItandibbLP}>
      <ZennichiGlobalNavigation />
      <section className={style.HeroSection} id="hero">
        <div className={style.HeroLayout}>
          <figure className={style.HeroDecorationCircle} />
          <img
            className={style.HeroDecorationLetterI}
            alt=""
            src="/assets/itandi_bb/letter-I-1.svg"
          />
          <div className={style.HeroContents}>
            <h1>
              <div className={style.HeroLogo}>
                <img
                  className={style.HeroLogoimage}
                  alt="ITANDI"
                  src="/assets/logo_itandi.svg"
                />
                <img
                  className={style.HeroLogoimage}
                  alt="rabbynet_bb"
                  src="/assets/zennichi/logo_rabbynet_bb.svg"
                />
              </div>
              <div className={style.HeroMaincopy}>
                <strong>不動産賃貸取引を</strong>
                <strong>なめらかにする</strong>
                <strong>DXサービス</strong>
              </div>
            </h1>
            <ul className={style.HeroFeatureLists}>
              <HeroFeatureListItem
                serviceName="IKC"
                href="/zennichi/itandi-kanri-cloud/"
                title="賃貸管理"
              />
              <HeroFeatureListItem
                serviceName="NK"
                href="/zennichi/naiken-yoyaku/"
                title="内見予約"
              />
              <HeroFeatureListItem
                serviceName="MU"
                href="/zennichi/moushikomi-uketsuke/"
                title="入居申込"
              />
              <HeroFeatureListItem
                serviceName="DK"
                href="/zennichi/denshi-keiyaku/"
                title="電子契約"
              />
              <HeroFeatureListItem
                serviceName="KT"
                href="/zennichi/koushin-taikyo/"
                title="入居者管理"
              />
              <HeroFeatureListItem
                serviceName="BK"
                href="/zennichi/bukkaku/"
                title="物確電話応答"
              />
              <HeroFeatureListItem
                serviceName="NSK"
                href="/zennichi/naiso-kouji-omakase/"
                title="内装工事"
              />
              <HeroFeatureListItem
                serviceName="NC"
                href="/zennichi/crm/"
                title="顧客管理"
              />
            </ul>
          </div>
          <img
            className={style.HeroPicture}
            alt="使用イメージ"
            src="/assets/zennichi/zennichi_hero_image_202307.png"
          />
        </div>
        <div className={style.HeroRubbynetFeatureLayout}>
          <div className={style.HeroRubbynetFeature}>
            <img
              className={style.HeroRubbynetImage}
              alt="ラビーちゃん"
              src="/assets/zennichi/rabby-chan_image.png"
            />
            <div className={style.HeroRubbynetFeatureContentsWrapper}>
              <div className={style.HeroRubbynetFeatureCopy}>
                ラビーネットBBと連携！
                <br />
                リアルタイムな物件情報で
                <br className={style.HeroSPOnly} />
                業務効率化
              </div>
              <ol className={style.HeroRubbynetFeatureContents}>
                <li className={style.HeroRubbynetFeatureItem}>
                  <div className={style.HeroRubbynetIconPhone} />
                  <p className={style.HeroRubbynetFeatureText}>
                    電話・FAXでの
                    <br />
                    <span>物件確認が不要</span>に
                  </p>
                </li>
                <li className={style.HeroRubbynetFeatureItem}>
                  <div className={style.HeroRubbynetIconPc} />
                  <p className={style.HeroRubbynetFeatureText}>
                    物件検索からそのまま
                    <br className={style.HeroTabletOnly} />
                    <span>内見、申込、契約まで</span>
                  </p>
                </li>
                <li className={style.HeroRubbynetFeatureItem}>
                  <div className={style.HeroRubbynetIconMail} />
                  <p className={style.HeroRubbynetFeatureText}>
                    <span>最新の空室情報</span>を
                    <br className={style.HeroTabletOnly} />
                    顧客に自動返信
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className={style.AboutSection} id="about">
        <div className={style.AboutLayout}>
          <figure className={style.AboutDecorationCircle} />
          <img
            className={style.AboutDecorationLetterT}
            alt
            src="/assets/itandi_bb/letter-T.svg"
          />
          <img
            className={style.AboutDecorationLetterA}
            alt
            src="/assets/itandi_bb/letter-A.svg"
          />
          <img
            className={style.AboutDecorationLetterN}
            alt
            src="/assets/itandi_bb/letter-N.svg"
          />
          <div className={style.AboutContent}>
            <h2 className={style.AboutSubcopy}>サービス一覧</h2>
            <div className={style.AboutMaincopy}>
              1機能から導入でき、
              <br />
              組み合わせて
              <br className={style.AboutTabletOnly} />
              もっと便利に。
            </div>
            <img
              className={style.AboutIllust}
              alt
              src="/assets/itandi_bb/itandi_bb_illust-about.svg"
            />
            <ul className={style.AboutFeatures}>
              <AboutFeatureCard
                serviceName="IKC"
                href="/zennichi/itandi-kanri-cloud/"
                title="賃貸管理"
                copy="賃貸管理業務を一括して効率化できるシステム"
                imgAlt="イタンジ管理クラウド"
                imgSrc="/assets/service_logo/IKC_logo_black.svg"
              />
              <AboutFeatureCard
                serviceName="NK"
                href="/zennichi/naiken-yoyaku/"
                title="内見予約"
                copy="休日・時間外も24時間予約自動対応。入居率UPへ"
                imgAlt="内見予約くん"
                imgSrc="/assets/service_logo/itandibbplus_NK.svg"
              />
              <AboutFeatureCard
                serviceName="MU"
                href="/zennichi/moushikomi-uketsuke/"
                title="申込受付"
                copy="記入漏れ・手戻りゼロへ。確認連絡コストを削減"
                imgAlt="申込受付くん"
                imgSrc="/assets/service_logo/itandibbplus_MU.svg"
              />
              <AboutFeatureCard
                serviceName="DK"
                href="/zennichi/denshi-keiyaku/"
                title="電子契約"
                copy="非対面・ペーパーレスで、人と環境にやさしい契約"
                imgAlt="電子契約くん"
                imgSrc="/assets/service_logo/itandibbplus_DK.svg"
              />
              <AboutFeatureCard
                serviceName="KT"
                href="/zennichi/koushin-taikyo/"
                title="入居者管理"
                copy="入居中の問い合わせ、更新や退去をWebで管理。入居者とのやり取りを効率化"
                imgAlt="入居者管理くん"
                imgSrc="/assets/service_logo/itandibbplus_KT.svg"
              />
              <AboutFeatureCard
                serviceName="BK"
                href="/zennichi/bukkaku/"
                title="物確電話応答"
                copy="業務時間外も物確電話に自動応答。働き方改革へ"
                imgAlt="ぶっかくん"
                imgSrc="/assets/service_logo/itandibbplus_BK.svg"
              />
              <AboutFeatureCard
                serviceName="NSK"
                href="/zennichi/naiso-kouji-omakase/"
                title="内装工事"
                copy="面倒な原状回復をまるっとおまかせ"
                imgAlt="内装工事くん"
                imgSrc="/assets/service_logo/itandibbplus_NSK_omakase.svg"
              />
              <AboutFeatureCard
                serviceName="NC"
                href="/zennichi/crm/"
                title="顧客管理と追客"
                copy="顧客の一元管理、物件提案を自動化。追客業務を効率化し、来店率を向上する"
                imgAlt="ノマドクラウド"
                imgSrc="/assets/service_logo/itandibbplus_NC.svg"
              />
            </ul>
          </div>
        </div>
      </section>
      <section className={style.InterviewSection} id="interview">
        <div className={style.InterviewLayout}>
          <h2 className={style.InterviewSubcopy}>導入企業様の生の声</h2>
          <div className={style.InterviewMaincopy}>導入インタビュー</div>
          <div className={style.InterviewCardList}>
            <InterviewCard
              href="https://itanzine.itandi.co.jp/interview/fujikensetsu-shinjuku"
              imgSrc="/assets/itandi_bb/fujikensetsu.jpg"
              imgAlt="株式会社富士建設工業新宿支店様"
              title="お客様ファーストでIT化を推進し、内見予約くんと申込受付くんを導入！株式会社富士建設工業新宿支店様"
            />
            <InterviewCard
              href="https://itanzine.itandi.co.jp/interview/tokyo-tatemono"
              imgSrc="/assets/itandi_bb/toutatesama-1.jpg"
              imgAlt="東京建物不動産販売株式会社様"
              title="イタンジの一気通貫サービスを活用しリモート勤務を実現！コロナショックにも適応した東京建物不動産販売株式会社様"
            />
            <InterviewCard
              href="https://magazine.itandi.co.jp/casestudy/380/"
              imgSrc="/assets/itandi_bb/arz-planning.jpg"
              imgAlt="有限会社アルズプランニング様"
              title="北海道で初めて『ITANDI BB+』を導入し成果を実感。有限会社アルズプランニング様"
            />
          </div>
          <div className={style.InterviewLinkContainer}>
            <a
              native
              className={style.InterviewLink}
              href="https://itanzine.itandi.co.jp/tag/導入インタビュー"
              target="_blank"
              rel="noopener noreferrer"
            >
              全ての導入インタビューを見る
            </a>
          </div>
        </div>
      </section>
      <section className={style.OptionkunSection} id="optionkun">
        <div className={style.OptionkunLayout}>
          <figure className={style.OptionkunDecorationCircle} />
          <img
            className={style.OptionkunDecorationLetterD}
            alt
            src="/assets/itandi_bb/letter-D.svg"
          />
          <div className={style.OptionkunContent}>
            <h2 className={style.ItandibbplusHeadingContainer}>
              <span className={style.ItandibbplusHeadingSubtitle}>
                不動産賃貸業務のDXサービス群
              </span>
              <img
                className={style.ItandibbplusHeadingImage}
                alt
                src="/assets/service_logo/itandibbplus_black.svg"
              />
              <span className={style.ItandibbplusHeadingTitle}>
                イタンジビービー プラス
              </span>
            </h2>
            <ul className={style.OptionkunItems}>
              <li className={style.OptionkunItem} id="optionkun_IKC">
                <OptionkunItemLogoAndTitle
                  href="/zennichi/itandi-kanri-cloud/"
                  logoSrc="/assets/service_logo/IKC_logo_black.svg"
                  serviceName="IKC"
                  title="イタンジ管理クラウド"
                  description={`賃貸管理業務を一括して\n効率化できるシステム`}
                />
                <OptionkunItemLayout
                  heading="入金消込/オーナー送金/請求を一元管理"
                  description="入手金・未収金管理全般を一元管理できます。またファームバンキングやネットバンキングと連携し、入金消込業務の自動化、総合振込等も可能です。"
                  imgSrc="/assets/itandi_bb/IKC_function_01.png"
                />
                <OptionkunItemLayout
                  heading={`物件・家主情報を一元管理して、\n楽々契約書・帳票出力`}
                  description="建物・部屋・家主情報を一元的に管理することで、契約書や帳票の出力時に情報連携され転記作業やミスを大幅に軽減することができます。"
                  imgSrc="/assets/itandi_bb/IKC_function_02.png"
                />
                <div className={style.OptionkunItemButtons}>
                  <OutlinedButton
                    serviceName="IKC"
                    href="/zennichi/itandi-kanri-cloud/"
                    text="イタンジ管理クラウドへ"
                  />
                </div>
              </li>
              <li className={style.OptionkunItem} id="optionkun_NK">
                <OptionkunItemLogoAndTitle
                  href="/zennichi/naiken-yoyaku/"
                  logoSrc="/assets/service_logo/itandibbplus_NK.svg"
                  serviceName="NK"
                  title="内見予約くん"
                  description={`24時間365日オンラインで\nいつでも内見受付`}
                />
                <OptionkunItemLayout
                  heading="内見予約数が15%もUP"
                  description="業務外の時間帯でもオンライン上で内見予約を受付けることができるので、今まで取り逃がしていた内見予約を全て受付することができます。"
                  imgSrc="/assets/itandi_bb/performance01.png"
                />
                <OptionkunItemLayout
                  heading="スタッフの電話・FAXコストを半減"
                  description="内見受付にスタッフが対応する必要がありません。今まで電話やFAXでやりとりしていた人件費や通信費をカットすることができます。"
                  imgSrc="/assets/itandi_bb/performance02.png"
                />
                <div className={style.OptionkunItemButtons}>
                  <OutlinedButton
                    serviceName="NK"
                    href="/zennichi/naiken-yoyaku/"
                    text="内見予約くんへ"
                  />
                </div>
              </li>
              <li className={style.OptionkunItem} id="optionkun_MUDK">
                <OptionkunItemLogoAndTitle
                  href="/zennichi/moushikomi-uketsuke/"
                  logoSrc="/assets/service_logo/itandibbplus_MU.svg"
                  serviceName="MU"
                  title="申込受付くん・電子契約くん"
                  description={`利用数No.1\n入居申込のWeb受付システム`}
                />
                <OptionkunItemLayout
                  heading="紙の申込書管理を削減"
                  description="Web申込機能により、無駄な紙のコストを削減します。必要な時は、PDFから紙に印刷することも可能です。"
                  imgSrc="/assets/itandi_bb/mu_performance01.png"
                />
                <OptionkunItemLayout
                  heading="申込進捗確認の時間を大幅削減"
                  description="申込状況が一目でわかる画面なので、申込がどこで止まっているか状況を共有します。進捗連絡などはチャットメッセージで時間を問わずに送信でき、コミュニケーションコストを削減できます。"
                  imgSrc="/assets/itandi_bb/performance03.png"
                />
                <div className={style.OptionkunItemButtons}>
                  <OutlinedButton
                    serviceName="MU"
                    href="/zennichi/moushikomi-uketsuke/"
                    text="申込受付くんへ"
                  />
                  <OutlinedButton
                    serviceName="DK"
                    href="/zennichi/denshi-keiyaku/"
                    text="電子契約くんへ"
                  />
                </div>
              </li>
              <li className={style.OptionkunItem} id="optionkun_KT">
                <OptionkunItemLogoAndTitle
                  href="/zennichi/koushin-taikyo/"
                  logoSrc="/assets/service_logo/itandibbplus_KT.svg"
                  title="入居者管理くん"
                  serviceName="KT"
                  description={`入居中の問い合わせ\n更新や退去もWebで管理`}
                />
                <OptionkunItemLayout
                  heading={`重要な書類もスムーズに電子契約`}
                  description={`更新合意書や退去時の敷金精算書などの重要な書類は、電子契約くんとの連携によって、スムーズに電子契約を行うことが可能です。`}
                  imgSrc="/assets/koushin_taikyo/KT01.png"
                />
                <OptionkunItemLayout
                  heading="更新・退去手続きの進捗をWebで管理"
                  description="入居者の「更新間近」「退去手続き中」など、状況をWeb上で確認・管理することができます。物件ごとの入居者状況をWebで管理できるため、電話確認など無駄なやりとりをカットできます。"
                  imgSrc="/assets/itandi_bb/KT02.png"
                />
                <div className={style.OptionkunItemButtons}>
                  <OutlinedButton
                    serviceName="KT"
                    href="/zennichi/koushin-taikyo/"
                    text="入居者管理くんへ"
                  />
                </div>
              </li>
              <li className={style.OptionkunItem} id="optionkun_BK">
                <OptionkunItemLogoAndTitle
                  href="/zennichi/bukkaku/"
                  logoSrc="/assets/service_logo/itandibbplus_BK.svg"
                  serviceName="BK"
                  title="ぶっかくん"
                  description={`物確対応を自動化して\n業務効率アップ！`}
                />
                <OptionkunItemLayout
                  heading={`24時間対応で営業時間外や定休日もOK\n反響集客力が15%UP!`}
                  description="「ぶっかくん」が貴社に代わって、空室確認を自動音声で24時間いつでも応答します。営業時間外、定休日、不在時などにも自動応答するので、今まで取り逃していた収益機会を逃さず、反響数がアップします。"
                  imgSrc="/assets/itandi_bb/bk_performance01.png"
                />
                <OptionkunItemLayout
                  heading="空室状況の確認電話を約57%自動化"
                  description="物件確認電話に約57%で自動応答します。応答の内容もカスタマイズも可能で、空室状況だけでなく、内見方法、キーボックスの暗証番号の案内や図面自動FAX送信などが行えます。また、「ぶっかくん」なら一度に多数の電話に対応できるので、従業員は度重なる物件確認電話の応答に、業務時間を奪われません。"
                  imgSrc="/assets/itandi_bb/bk_performance02.png"
                />
                <div className={style.OptionkunItemButtons}>
                  <OutlinedButton
                    serviceName="BK"
                    href="/zennichi/bukkaku/"
                    text="ぶっかくんへ"
                  />
                </div>
              </li>
              <li className={style.OptionkunItem} id="optionkun_NSK">
                <OptionkunItemLogoAndTitleNSK
                  href="/zennichi/naiso-kouji-omakase/"
                  logoSrc="/assets/service_logo/itandibbplus_NSK_omakase.svg"
                  serviceName="NSK"
                  title="内装工事くん"
                  description={`面倒な原状回復を\nまるっとおまかせ`}
                />
                <OptionkunItemLayout
                  heading={`利用料0円で最適な工事発注を実現`}
                  description="原状回復工事における退去立会から工事発注、工事完了確認までの面倒な業務をイタンジが代わりに実施します。
                  イタンジの担当者が管理会社の要望をヒアリングし、パートナー工事会社様の特徴や過去の工事の実績と照らし合わせ、最適な工事発注を実現します。"
                  imgSrc="/assets/naiso_kouji_omakase/naiso_kouji_omakase_point01.png"
                />
                <div className={style.OptionkunItemButtons}>
                  <OutlinedButton
                    serviceName="NSK"
                    href="/zennichi/naiso-kouji-omakase/"
                    text="内装工事くんへ"
                  />
                </div>
              </li>
              <li className={style.OptionkunItem} id="optionkun_NC">
                <OptionkunItemLogoAndTitle
                  href="/zennichi/crm/"
                  logoSrc="/assets/service_logo/itandibbplus_NC.svg"
                  serviceName="NC"
                  title="ノマドクラウド"
                  description={`追客に特化した\n顧客管理システム`}
                />
                <OptionkunItemLayout
                  heading="反響の自動取り込みと通知"
                  description={`複数のポータルサイトに掲載中に反響通知を見逃したり、対応を忘れてしまうことありませんか？\n\nノマドクラウドは反響がシステムに即座に反映・通知されます。また、自動タスク管理機能により、対応漏れが防止でき、代理対応も可能になります。`}
                  imgSrc="/assets/crm/photo/NC_value_pc_202307.png"
                />
                <OptionkunItemLayout
                  heading="顧客の行動ログ分析"
                  description={`お客様が本当にお部屋探しをしているのか、ノマドクラウドなら見極められます。\n\n営業担当者様は、顧客のマイページを見ている時間帯やメールの開封履歴を見て、対応方法を検討すれば来店率が上がります。`}
                  imgSrc="/assets/crm/photo/NC_mock_tab_202307.png"
                />
                <OptionkunItemLayout
                  heading="空室確認問い合わせ反響の一次対応の自動化"
                  description={`リアルタイム業者間サイト「ITANDI BB」と連携し、空室状況を自動確認＆日程調整メールを自動送信。\n\n営業時間外の問い合わせに対する機会損失を防ぎます。`}
                  imgSrc="/assets/crm/photo/NC_mockup_mobile_line.png"
                />
                <OptionkunItemLayout
                  heading="顧客専用マイページ"
                  description={`せっかく反響が来たのに他店にお客様を取られてしまうことはありませんか？その理由の１つは、お客様が再度ポータルサイトでお部屋探しをしてしまうからです。\n\nお客様専用ページ（マイページ）で、お部屋探しをさせることで、ポータルサイトへの離脱を防ぎます。`}
                  imgSrc="/assets/crm/photo/NC_mockup_mobiles_mypage.png"
                />
                <div className={style.OptionkunItemButtons}>
                  <OutlinedButton
                    serviceName="NC"
                    href="/zennichi/crm/"
                    text="ノマドクラウドへ"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className={style.ContactSection} id="contact">
        <div className={style.ContactLayout}>
          <figure className={style.ContactDecorationCircle} />
          <img
            className={style.ContactDecorationLetterI2}
            alt
            src="/assets/itandi_bb/letter-I-2.svg"
          />
          <div className={style.ContactContent}>
            <h2 className={style.ContactSubcopy}>
              デモ体験・お見積り・資料請求
            </h2>
            <div className={style.ContactMaincopy}>お問い合わせ</div>
            <ZennichiContactForm />
          </div>
        </div>
      </section>
      <section className={style.NoteSection} id="note">
        <div className={style.NoteLayout}>
          <p>
            <small>
              ※物件確認不要は、紙書類等、ITANDI
              BBとシステム連携しない方法での入居申し込みの場合を除きます。
            </small>
          </p>
        </div>
      </section>
    </div>
  );
};

export default ZennichiItandiBB;
