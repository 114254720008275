import { h } from "preact";
import ZennichiGlobalNavigation from "../../../../components/ZennichiGlobalNavigation";
import style from "./style.scss";
import SectionHeading from "../../../../components/SectionHeading";

function SecondaryButton(props) {
  return (
    <a native className={style.SecondaryButtonContainer} href={props.href}>
      <p className={style.SecondaryButtonText}>{props.text}</p>
    </a>
  );
}

const NaisoKoujiOmakaseFlow = () => (
  <>
    <ZennichiGlobalNavigation />
    <div className={style.HeadArea}>
      <div className={style.HeadAreaWrapper}>
        <h1 className={style.HeadLogoContainer}>
          <img
            className={style.HeadLogoImage}
            src="/assets/service_logo/itandibbplus_NSK_omakase.svg"
            alt="内装工事くんおまかせプラン"
          />
        </h1>
        <p className={style.HeadText}>利用開始〜発注までの流れ</p>
      </div>
    </div>

    <section className={style.FlowSection}>
      <div className={style.FlowWrapper}>
        <div className={style.FlowStepContainer}>
          <SectionHeading title={`内装工事くんに登録する`} subTitle="STEP1" />
          <div className={style.FlowDetailContainer}>
            <div className={style.FlowMainContainer}>
              <p className={style.FlowText}>
                内装工事くん利用開始のために必要な項目を入力します。
                <br />
                <span className={style.FlowTextEmphasis}>
                  必ず以下のボタンから「ラビーネットBB」のアカウントにログインしてください。
                </span>
              </p>
              <div className={style.FlowButtonContainer}>
                <SecondaryButton
                  iconSrc="/assets/crm/icon/mail_white.svg"
                  text="内装工事くん（新規登録）"
                  href="https://naiso-kojikun.com/"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={style.FlowStepContainer}>
          <SectionHeading title={`案件依頼をする`} subTitle="STEP2" />
          <div className={style.FlowDetailContainer}>
            <div className={style.FlowMainContainer}>
              <p className={style.FlowText}>
                ※内装工事くんに案件登録後、現地調査の対応までに４〜５営業日いただきます。お時間に余裕を持って案件依頼をお願いいたします。緊急の場合はご相談ください。
                <br />
                ※空室物件の場合、鍵の郵送が必要なのであれば、鍵が届き次第の対応となります。
              </p>
              <div
                className={`${style.FlowButtonContainer} ${style.FlowThirdButtonContainer}`}
              >
                <div className={style.FlowButtonFlexContainer}>
                  <SecondaryButton
                    iconSrc="/assets/crm/icon/mail_white.svg"
                    text="詳しい使い方はこちら"
                    href="https://naiso-kojikun.zendesk.com/hc/ja/articles/12860437160851"
                  />
                </div>
                <div className={style.FlowButtonFlexContainer}>
                  <SecondaryButton
                    iconSrc="/assets/crm/icon/mail_white.svg"
                    text="お問い合わせ"
                    href="/naiso-kouji-omakase/#contact"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default NaisoKoujiOmakaseFlow;
