import { h } from "preact";
import style from "./style.scss";
import ZennichiGlobalNavigation from "../../../components/ZennichiGlobalNavigation";

function Heading({ title }) {
  return <h2 class={style.ContentHeading}>{title}</h2>;
}

function Paragraph({ description }) {
  return <p class={style.ContentParagraph}>{description}</p>;
}

function List({ listItems }) {
  return (
    <ol class={style.List}>
      {listItems.map((item) => (
        <li class={style.ListItem} key={item}>
          {item}
        </li>
      ))}
    </ol>
  );
}

const ZennichiPrivacy = () => (
  <>
    <ZennichiGlobalNavigation />
    <section class={style.HeroSection}>
      <h1 class={style.HeroHeading}>プライバシーポリシー</h1>
    </section>
    <div class={style.Main}>
      <p class={style.HeroParagraph}>
        イタンジ株式会社（以下、「当社」という。）の運営するITANDI
        BBでは、当社の個人情報保護方針に基づき、以下のとおり、プライバシーポリシーを定め、個人情報の適正な管理、保護の徹底を図ってまいります。
      </p>
      <section class={style.ContentSection}>
        <Heading title="個人情報とは" />
        <Paragraph description="個人情報とは、氏名、住所、電話番号、メールアドレス、生年月日、性別、勤務先、家族構成等によりユーザー自身を特定することができる情報を指します。" />
      </section>
      <section class={style.ContentSection}>
        <Heading title="個人情報の取得について" />
        <List
          listItems={[
            "当社は、ITANDI BBを通じて、ユーザーの名前、住所、勤務先、メールアドレス等の個人情報をユーザーから直接収集することはありません。",
            "当社は、パートナー（ITANDI BBを利用している企業をいいます。以下同じ。）がノマドクラウドを利用する際、当該パートナーとITANDI BB利用契約を締結し、当該パートナーがITANDI BBの利用に際して必要な範囲内で、個人情報の取扱いに関する業務の委託を受けます。当社は、当該業務の委託の範囲内で、個人情報を取扱うことがあります。",
          ]}
        />
      </section>
      <section class={style.ContentSection}>
        <Heading title="個人情報の利用目的について" />
        <Paragraph description="当社ホームページでは、パートナーからの個人情報の取扱いに関する業務の委託の範囲内で、ユーザーの個人情報を下記の目的のために使用することがあります。以下の利用目的の範囲を超えた取り扱いはいたしません。" />
        <List
          listItems={[
            "当社及びパートナーがコンテンツの表示、電子メール、メッセージ、ブラウザ上での通知等の方法でメッセージ送信を行うこと。",
            "当社及びパートナーに対する問い合わせ内容の正確な把握及び回答、情報の送付等の連絡。その他パートナーの同意がある場合。",
            "ユーザーの不正行為の監視。",
            "属性等の類推情報及び統計情報の作成。なお、当社は、当該情報を作成する際には、特定の個人が識別されないように、匿名化措置を講じます。",
            "システム上の問題の原因の特定やその他のパートナー提供サービスの改善のために必要なデータの閲覧、記録及び分析を行うこと。",
          ]}
        />
      </section>
      <section class={style.ContentSection}>
        <Heading title="個人情報の提供・開示について" />
        <Paragraph description="当社は、パートナーからご提供いただいた個人情報を、以下に該当する場合を除き、第三者に対し提供または開示しません。" />
        <List
          listItems={[
            "パートナーの同意がある場合",
            "法令等に基づく要請があり、開示が必要であると判断される場合",
            "前項の利用目的の達成に必要な範囲内において、個人情報の取扱い業務を全部又は一部を委託する場合。",
            " 合併その他の事由による事業の承継に伴って個人情報が提供される場合。",
          ]}
        />
      </section>
      <section class={style.ContentSection}>
        <Heading title="パートナーからの個人情報に関する開示・訂正・削除の請求について" />
        <Paragraph description="当社は、パートナーより個人情報の開示・訂正・削除等のご依頼があった場合は、当社所定の手続に従い、ご依頼者が本人または代理人であることを確認させていただいたうえで、合理的な範囲で速やかに対応いたします。" />
      </section>
      <section class={style.ContentSection}>
        <Heading title="個人情報の安全対策の実施について" />
        <Paragraph description="当社ホームページでは、ユーザーからご提供いただいた個人情報を、適切かつ厳重に管理し、個人情報への不正アクセスや個人情報の紛失・破壊・改ざん・漏えい等の防止に必要かつ十分な安全管理対策を実施します。" />
      </section>
      <section class={style.ContentSection}>
        <Heading title="プライバシー・ポリシーの改定について" />
        <Paragraph description="当社は、ユーザーの個人情報をより一層保護するため、本ポリシーにおける取り組みについて、適宜見直し、改定いたします。なお、本ポリシーの改定につきましては、当社ホームページで随時掲載いたします。" />
      </section>
      <section class={style.ContentSection}>
        <Heading title="個人情報についてのお問い合わせ先" />
        <Paragraph description="本ポリシーおよび当社の個人情報の取り扱いに関するご意見・ご質問などはこちらまでご連絡ください。" />
        <p class={style.ContentParagraph}>
          イタンジ株式会社　個人情報担当窓口
          <br />
          住所： 東京都港区六本木3-2-1　住友不動産六本木グランドタワー42F
          <br />
          TEL：03-6441-3954（受付時間　平日10:00～18:00）
          <br />
          FAX：03-3560-5601
        </p>
      </section>
    </div>
  </>
);

export default ZennichiPrivacy;
