import { h } from "preact";
import style from "./style.scss";

const Footer = () => (
  <footer class={style.Footer}>
    <ul class={style.FooterNav}>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/">
          TOP
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/bb-detail/">
          ITANDI BBとは
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/itandi-kanri-cloud/">
          賃貸管理
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/bukkaku/">
          物件確認応答
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/naiken-yoyaku/">
          内見予約
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/moushikomi-uketsuke/">
          申込受付
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/denshi-keiyaku/">
          電子契約
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/koushin-taikyo/">
          入居者管理
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/naiso-kouji-omakase/">
          内装工事
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/rpa-ocr/">
          RPA
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/crm/">
          顧客管理
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/baibai-bukkaku/">
          売買物確
        </a>
      </li>
    </ul>
    <ul class={style.FooterNav}>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="https://www.itandi.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          会社案内
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/privacy">
          プライバシーポリシー
        </a>
      </li>
    </ul>
    <p class={style.Copyright}>© ITANDI, Inc. All rights reserved.</p>
  </footer>
);

export default Footer;
