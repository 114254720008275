import { h } from "preact";
import style from "./style.scss";
import useAnchorHandle from "../../util/useAnchorHandle";

const ZennichiGlobalNavigation = () => {
  useAnchorHandle();
  return (
    <nav class={style.Nav}>
      <a native class={style.NavItem} href="/zennichi/itandi-kanri-cloud/">
        ITANDI管理クラウド
      </a>
      <a native class={style.NavItem} href="/zennichi/ikkatsu-syukko/">
        一括出稿
      </a>
      <a native class={style.NavItem} href="/zennichi/bukkaku/">
        物確電話応答
      </a>
      <a native class={style.NavItem} href="/zennichi/naiken-yoyaku/">
        内見予約受付
      </a>
      <a native class={style.NavItem} href="/zennichi/moushikomi-uketsuke/">
        Web入居申込
      </a>
      <a native class={style.NavItem} href="/zennichi/denshi-keiyaku/">
        電子契約
      </a>
      <a native class={style.NavItem} href="/zennichi/koushin-taikyo/">
        入居者管理
      </a>
      <a native class={style.NavItem} href="/zennichi/naiso-kouji-omakase/">
        内装工事
      </a>
      <a native class={style.NavItem} href="/zennichi/crm/">
        顧客管理
      </a>
    </nav>
  );
};

export default ZennichiGlobalNavigation;
