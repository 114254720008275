import { h } from "preact";
import ZennichiGlobalNavigation from "../../../components/ZennichiGlobalNavigation";
import ZennichiContactForm from "../../../components/ZennichiContactForm";
import SectionHeading from "../../../components/SectionHeadingColord";
import FeatureItem from "../../../components/FeatureItem";
import BenefitItem from "../../../components/BenefitItem";
import AchievementVoiceItem from "../../../components/AchievementVoiceItem";
import AchievementTableItem from "../../../components/AchievementTableItem";
import StepItem from "../../../components/StepItem";
import OptionCard from "../../../components/OptionCard";
import style from "./style.scss";
import ZennichiIndex from "../../../components/ZennichiIndex";

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <img className={style.PrimaryButtonIcon} src={props.iconSrc} alt="" />
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function CTAArea() {
  return (
    <section className={style.CTAAreaSection}>
      <div className={style.CTAAreaWrapper}>
        <p className={style.CTAAreaCopyText}>
          物確応答の自動化を
          <br />
          <span className={style.CTAAreaCopyStorong}>特別価格</span>
          で始めませんか？
        </p>
        <div className={style.CTAAreaButtonContainer}>
          <PrimaryButton
            iconSrc="/assets/bukkaku/mail-line.svg"
            text="資料請求・お問い合わせ"
            href="#contact"
          />
        </div>
      </div>
    </section>
  );
}

const ZennichiBukkaku = () => (
  <>
    <ZennichiGlobalNavigation />
    <section className={style.FirstViewSection}>
      <div className={style.FirstViewWrapper}>
        <div className={style.ZennichiIndexLayout}>
          <ZennichiIndex />
        </div>
        <h1 className={style.FirstViewDescription}>
          <img
            className={style.FirstViewDescriptionImage}
            alt="物確対応を自動化して業務効率アップ！ 物確電話自動対応「ぶっかくん」で不動産管理会社の電話対応時間を削減。"
            src="/assets/bukkaku/mv_pctext.png"
          />
        </h1>
        <div className={style.FirstViewKeyVisualContainer}>
          <img
            className={style.FirstViewKeyVisualPC}
            src="/assets/bukkaku/BK_mockup_202307.png"
            alt="Mv pcimage"
          />
          <img
            className={style.FirstViewKeyVisualSP}
            src="/assets/bukkaku/BK_mockup_202307.png"
            alt="Mv spimage"
          />
        </div>
      </div>
    </section>
    <section className={style.LogoAndCopySection}>
      <div className={style.LogoAndCopyWrapper}>
        <img
          className={style.LogoAndCopyImagePC}
          src="/assets/bukkaku/mv_pcsecondary.png"
          alt="Mv pcsecondary"
        />
        <img
          className={style.LogoAndCopyImageSP}
          src="/assets/bukkaku/mv_spsecondary.png"
          alt="Mv spsecondary"
        />
        <a native className={style.CTAButton} href="#contact">
          資料請求・お問い合わせ
        </a>
        <a native className={style.DemoBannerContainer} href="tel:05031597300">
          <p className={style.DemoBannerCopy}>自動応答デモを今すぐお試し</p>
          <p className={style.DemoBannerPhoneNumber}>050-3159-7300</p>
          <div className={style.DemoBannerNumberImageContainer}>
            <img
              className={style.DemoBannerNumberImage}
              src="/assets/bukkaku/BkDemoNumber.svg"
              alt="1# 60000#"
            />
          </div>
        </a>
      </div>
    </section>
    <section className={style.AbstractSection}>
      <img
        className={style.AbstractImage}
        src="/assets/bukkaku/bukkakun_conseptual.png"
        alt="Bukkakun conseptual"
      />
    </section>
    <section className={style.FeaturesSection}>
      <div className={style.FeaturesWrapper}>
        <SectionHeading title="機能詳細" serviceName="BK" />
        <div className={style.FeaturesContainer}>
          <FeatureItem
            imgSrc="/assets/bukkaku/detail-icon01.png"
            imgAlt=""
            title="24時間365日対応"
            description="物件確認の電話にいつでも対応します。今まで電話対応していた時間が削減され、大幅に業務を効率化できます。"
          />
          <FeatureItem
            imgSrc="/assets/bukkaku/detail-icon02.png"
            imgAlt=""
            title="自動図面送信"
            description="物確応答時に希望社に図面を送付することができます。仲介会社様のFAX番号を特定しスムーズに送信します。"
          />
          <FeatureItem
            imgSrc="/assets/bukkaku/detail-icon03.png"
            imgAlt=""
            title="柔軟なアナウンス設定"
            description="募集状況、入居可能日、内見方法などアナウンス設定を自在にカスタマイズできます。"
          />
          <FeatureItem
            imgSrc="/assets/bukkaku/detail-icon04.png"
            imgAlt=""
            title={`内見予約の自動受付\n（オプション）`}
            description="オプションの「内見予約くん」にも申込いただくと、物件確認の際に、内見予約の案内と自動受付を行います。"
          />
          <FeatureItem
            imgSrc="/assets/bukkaku/detail-icon05.png"
            imgAlt=""
            title="電話反響を計測しレポート"
            description="自動応答した物確の反響数を計測しレポートします。人気物件の表示や、物件の問い合わせ数、入電者情報、時間毎の反響分布、入電が多い仲介会社などのデータが可視化されます。"
          />
          <FeatureItem
            imgSrc="/assets/bukkaku/detail-icon06.png"
            imgAlt=""
            title={`音声認識で物件確認可能\n（オプション）`}
            description="従来のダイヤルプッシュ操作による物件特定に加え、音声認識機能を追加しました。発話から物件を特定し、自動応答することが可能です。"
          />
        </div>
      </div>
    </section>
    <section className={style.MovieSection}>
      <div className={style.MovieContainer}>
        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          frameBorder={0}
          height={315}
          src="https://www.youtube.com/embed/p_o-UlRUzcE"
          width={560}
        />
      </div>
    </section>
    <section className={style.BenefitSection}>
      <div className={style.BenefitWrapper}>
        <BenefitItem
          imgSrc="/assets/bukkaku/performance01.png"
          imgAlt=""
          title="導入効果 1"
          copy={`24時間対応なので営業時間外や\n定休日も対応、反響集客力が15%UP!`}
          description={`「ぶっかくん」が貴社に代わって、空室確認を自動音声で24時間いつでも応答します。\n営業時間外、定休日、不在時などにも自動応答するので、今まで取り逃していた収益機会を逃さず、反響数がアップします。`}
        />
        <BenefitItem
          imgSrc="/assets/bukkaku/performance02.png"
          imgAlt=""
          title="導入効果 ２"
          copy={`空室状況の確認電話を\n57%自動化でコストカット`}
          description={`物件確認電話に約60%で自動応答します。\n応答の内容もカスタマイズも可能で、空室状況だけでなく、内見方法、キーボックスの暗証番号の案内や図面自動FAX送信などが行えます。\nまた、「ぶっかくん」なら一度に多数の電話に対応できるので、従業員は度重なる物件確認電話の応答に、業務時間を奪われません。`}
        />
        <BenefitItem
          imgSrc="/assets/bukkaku/performance03.png"
          imgAlt=""
          title="導入効果 ３"
          copy={`電話反響レポートの\nデータ分析により空室率を削減`}
          description={`今までわかりづらかった電話反響を計測しレポートします。\n人気物件の表示や、物件の問い合わせ数、入電者情報、時間毎の反響分布、入電が多い仲介会社などのデータが可視化されます。オーナーへのレポートや業務分析に役立てることができます。`}
        />
        <BenefitItem
          imgSrc="/assets/bukkaku/performance04.png"
          imgAlt=""
          title="導入効果 ４"
          copy={`音声認識による物件特定で\n応答時間を56%短縮`}
          description={`従来のダイヤルプッシュ操作による物件特定に加え、音声認識機能を追加。\n発話から物件を特定し、自動応答することが可能になりました。\nこれにより、仲介会社様の物確確認時間を短縮し、操作の手間を約60%程度、削減します。`}
        />
      </div>
    </section>
    <CTAArea />
    <section className={style.AchievementSection}>
      <div className={style.AchievementWrapper}>
        <SectionHeading title="導入企業様の声" serviceName="BK" />
        <div className={style.AchievementContainer}>
          <div className={style.AchievementVoiceList}>
            <AchievementVoiceItem
              imgSrc="/assets/bukkaku/voice01.png"
              imgAlt=""
              description={`ぶっかくんにより業務効率が大幅に改善し、お客様対応に注力。直付けの比率が前年比10％UP。\n株式会社ナミキ様`}
            />
            <AchievementVoiceItem
              imgSrc="/assets/bukkaku/voice02.png"
              imgAlt=""
              description={`物件確認の応対や内見予約などの業務を効率化。オーナー様報告に注力し満足度アップを実現。\n―株式会社メイクスマネジメント様（ぶっかくん・内見予約くん導入）`}
            />
            <AchievementVoiceItem
              imgSrc="/assets/bukkaku/voice03.png"
              imgAlt=""
              description={`空室確認のみの受電数を削減し、残業時間の短縮を実現自社システムとの連携で、タイムリーな情報発信が可能に。\n― パナソニックホームズ不動産株式会社様（ぶっかくん導入）`}
            />
            <a
              native
              className={style.AchievementVoicesLink}
              href="https://www.itandi.co.jp/casestudies"
              target="_blank"
              rel="noopener noreferrer"
            >
              ▶︎ 導入事例をすべて見る
            </a>
          </div>
          <div className={style.AchievementTableContainer}>
            <div className={style.AchievementTableHeaderContainer}>
              <h3 className={style.AchievementTableHeaderTitle}>実績</h3>
              <p className={style.AchievementTableHeaderCaption}>
                ※2023年1月末時点
              </p>
            </div>
            <AchievementTableItem
              heading="仲介利用拠点"
              contentUnitSmall="約"
              contentNumber="51,000"
              contentUnit="店舗"
              serviceName="BK"
            />
          </div>
        </div>
      </div>
    </section>
    <section className={style.AchievementCompanySection}>
      <div className={style.AchievementCompanyWrapper}>
        <SectionHeading title="導入実績" serviceName="BK" />
        <ul className={style.AchievementCompanyList}>
          <li className={style.AchievementCompanyItem}>
            <img
              src="/assets/bukkaku/cl_logo04.png"
              alt="セルコーポレーション"
              className={style.AchievementCompanyLogo}
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              src="/assets/bukkaku/cl_logo05.png"
              alt="パナソニックホームズアンドリビング"
              className={style.AchievementCompanyLogo}
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              src="/assets/bukkaku/cl_logo07.png"
              alt="シーエフネッツ"
              className={style.AchievementCompanyLogo}
            />
          </li>
        </ul>
      </div>
    </section>
    <section className={style.StepSection}>
      <div className={style.StepWrapper}>
        <SectionHeading title="導入開始までの流れ" serviceName="BK" />
        <div className={style.StepContainer}>
          <StepItem
            imgSrc="/assets/bukkaku/step-image01.png"
            imgAlt=""
            title="STEP 1　管理会社様のご準備"
            description={`①物件データ（.xlsx, .csv）ファイル\n②アナウンス内容\nをお決めいただきます。`}
          />
          <StepItem
            imgSrc="/assets/bukkaku/step-image02.png"
            imgAlt=""
            title="STEP 2　アナウンス内容の確認"
            description={`利用開始が近づきましたら、\nぶっかくんと紐づく電話番号を発行いたします。\n実際のアナウンス内容を確認いただきます。`}
          />
          <StepItem
            imgSrc="/assets/bukkaku/step-image03.png"
            imgAlt=""
            title="STEP 3　客付会社様に周知して開始"
            description={`弊社にて初期設定します。お申し込みからご利用開始まで3~7営業日いただきます。電話番号の取得、設備機器の購入等は不要です。`}
          />
        </div>
      </div>
    </section>
    <section className={style.StepSection}>
      <div className={style.StepWrapper}>
        <SectionHeading title="導入後にやることは2点のみ！" serviceName="BK" />
        <div className={style.StepContainer}>
          <StepItem
            imgSrc="/assets/bukkaku/step-image04.png"
            imgAlt=""
            title="管理会社様→物件データのご登録作業"
            description={`管理画面で募集ステータスボタンを押下いただくことで、アナウンス内容がリアルタイムで切り替わります。`}
          />
          <StepItem
            imgSrc="/assets/bukkaku/step-image05.png"
            imgAlt=""
            title="募集ステータス（募集中/募集停止）の更新作業"
            description={`物件データの登録は、ファイルアップロードによる一括登録も可能です。一括登録に要する時間は２~３分程度です。`}
          />
        </div>
      </div>
    </section>
    <section className={style.OptionSection}>
      <div className={style.OptionWrapper}>
        <SectionHeading
          title="合わせて使うと便利なオプション"
          serviceName="BK"
        />
        <div className={style.OptionContainer}>
          <OptionCard
            href="/zennichi/moushikomi-uketsuke/"
            logoSrc="/assets/bukkaku/moshikomiuketsukekun.png"
            productName="申込受付くん"
            title="申込書提出から審査完了までをスムーズに"
            description="入居申込書のWeb受付システム。申込書提出から審査までのやり取りの時短と効率化を実現。"
            tagKanri={true}
            tagChukai={false}
          />
          <OptionCard
            href="/zennichi/naiken-yoyaku/"
            logoSrc="/assets/bukkaku/naikenyoyakukun.png"
            productName="内見予約くん"
            title="24時間365日オンラインでいつでも内見受付"
            description="内見予約のWeb受付システム。電話・FAXの通信費や人件費をカットし、内見の機会損失をゼロに。"
            tagKanri={true}
            tagChukai={false}
          />
        </div>
      </div>
    </section>
    <section className={style.ContactSection}>
      <div className={style.ContactWrapper} id="contact">
        <SectionHeading title="資料請求" serviceName="BK" />
        <ZennichiContactForm />
      </div>
    </section>
  </>
);

export default ZennichiBukkaku;
