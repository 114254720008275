import { h } from "preact";
import ZennichiGlobalNavigation from "../../../components/ZennichiGlobalNavigation";
import ZennichiContactForm from "../../../components/ZennichiContactForm";
import RectangleButtonWithIcon from "../../../components/RectangleButtonWithIcon";
import SectionHeading from "../../../components/SectionHeadingWithUnderBar";
import FeatureItem from "../../../components/FeatureItem";
import BenefitItem from "../../../components/BenefitItem";
import style from "./style.scss";

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

const ZennichiNaisoKouji = () => (
  <>
    <ZennichiGlobalNavigation />
    <section className={style.FirstviewSection}>
      <div className={style.FirstviewLayout}>
        <div className={style.FirstviewLayoutLeft}>
          <p className={style.FirstviewZennichiText}>
            【全日会員様向けプロダクトご紹介ページ】
          </p>
          <h1 className={style.FirstviewHeading}>
            <img
              alt="原状回復・修繕工事をWebで一元管理「内装工事くん」"
              src="/assets/naiso_kouji/headline_202311.svg"
            />
          </h1>
          <div className={style.FirstviewButton}>
            <div className={style.FirstviewButtonContact}>
              <RectangleButtonWithIcon
                href="#contact"
                iconSrc="/assets/naiso_kouji/download_NSKblue.svg"
                iconAlt="Download nkblue"
                text="資料請求・お問い合わせはこちら"
                colorStyle="NSKWhiteFill"
              />
            </div>
            <div className={style.FirstviewButtonOmakase}>
              <RectangleButtonWithIcon
                href="/zennichi/naiso-kouji-omakase/"
                iconSrc="/assets/naiso_kouji/tools-line.svg"
                iconAlt="Download nkblue"
                text="施工会社様探しでお困りの方はこちら"
                colorStyle="NSKWhiteLine"
              />
            </div>
          </div>
        </div>
        <div className={style.FirstviewLayoutRight}>
          <img
            alt="退去業務負担を約50%削減　※月間約200件の退去業務を行っている導入企業様での削減例です"
            className={style.FirstviewImage}
            src="/assets/naiso_kouji/NSK_mockup_20230426.png"
          />
        </div>
      </div>
    </section>
    <section className={style.LogoareaSection}>
      <div className={style.LogoareaWrapper}>
        <img
          alt="更新・退去手続き管理の負担を削減　入居者状況や手続きをWebで管理"
          className={style.LogoareaImagePC}
          src="/assets/naiso_kouji/FirstViewLayout_202311.svg"
        />
        <img
          alt="更新・退去手続き管理の負担を削減　入居者状況や手続きをWebで管理"
          className={style.LogoareaImageSP}
          src="/assets/naiso_kouji/FirstViewLayout_202311-sp.svg"
        />
      </div>
    </section>
    <section className={style.SummarySection}>
      <SectionHeading title="内装工事くんとは" serviceName="NSK" />
      <div className={style.SummarySchematic}>
        <img
          alt
          className={style.SummarySchematicIllust}
          src="/assets/naiso_kouji/schematic202311_nsk.png"
        />
      </div>
    </section>
    <section className={style.BenefitSection}>
      <SectionHeading title="内装工事くんでできること" serviceName="NSK" />

      <div className={style.BenefitWrapper}>
        <div className={style.BenefitPointWrapper}>
          <BenefitItem
            serviceName="NSK"
            imgSrc="/assets/naiso_kouji/naisokouji_point01.png"
            imgAlt=""
            title="ポイント 1"
            copy={`最短3クリックで工事の完了と書類管理まで実現\n業務が大幅に効率化`}
            description={`パートナー施工会社様とシステム経由でやりとりするだけで、管理会社様の操作は、立会依頼 / 見積承認 / 完了時確認 の3ステップの承認作業だけで完結。`}
          />
          <BenefitItem
            serviceName="NSK"
            imgSrc="/assets/naiso_kouji/naisokouji_point02.png"
            imgAlt=""
            title="ポイント ２"
            copy={`書類や写真は保存不要、\nだから抜け漏れの不安なし`}
            description={`やりとりした書類や写真はシステム上に自動保存されるので、これまで行っていたメールからの写真のダウンロードやローカルフォルダへのファイルの保存作業は不要です。`}
          />
          <BenefitItem
            serviceName="NSK"
            imgSrc="/assets/naiso_kouji/naisokouji_point03.png"
            imgAlt=""
            title="ポイント ３"
            copy={`工事関連の連絡量は1/4に削減可能`}
            description={`立会依頼や工事発注、工事日程の確認連絡など、これまでメールや電話で行っていた確認作業を削減でき、仲介会社様やオーナー様への対応スピードを向上できます。`}
          />
        </div>

        <div className={style.BenefitLeadWrapper}>
          <div className={style.BenefitLeadTextWrapper}>
            <p className={style.BenefitLeadTitle}>
              施工会社様が見つからずお困りの方
              <br />
              イタンジに工事発注をまるっとおまかせしませんか？
            </p>
            <p className={style.BenefitLeadText}>
              手間のかかる工事発注、工事完了確認までの業務をイタンジが代わりに実施します。パートナー施工会社様の特徴や過去の工事の実績と照らし合わせ、最適な工事発注を実現します。
            </p>
          </div>
          <div className={style.BenefitLeadContentsWrapper}>
            <div className={style.BenefitLeadContents}>
              <div className={style.BenefitLeadButtonWrapper}>
                <PrimaryButton
                  iconSrc="/assets/crm/icon/mail_white.svg"
                  text="詳しくはこちら"
                  href="/zennichi/naiso-kouji-omakase/"
                />
              </div>
              <div className={style.BenefitLeadLogoWrapper}>
                <img
                  src="/assets/service_logo/itandibbplus_NSK_omakase.svg"
                  alt="内装工事くん"
                  className={style.BenefitLeadLogo}
                />
              </div>
            </div>
            <div className={style.BenefitLeadImageWrapper}>
              <img
                src="/assets/naiso_kouji/nsk_omakase_lead.png"
                alt="工事会社様が見つからず焦る管理会社様"
                className={style.BenefitLeadImage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={style.FeaturesSection}>
      <div className={style.FeaturesWrapper}>
        <SectionHeading title="機能詳細" serviceName="NSK" />
        <div className={style.FeaturesContainer}>
          <FeatureItem
            imgSrc="/assets/naiso_kouji/computer-line.svg"
            imgAlt=""
            title={`Webでパートナー業者との\nやりとりを完結`}
            description="パートナー工事会社様にシステムにログインしていただくことで、Web上で原状回復工事にかかわるやりとりを行うことできます。"
          />
          <FeatureItem
            imgSrc="/assets/naiso_kouji/refresh-line.svg"
            imgAlt=""
            title={`メッセージ内の\n書類や写真を自動保存`}
            description={`Web上でやりとりした情報は案件ごとに自動で保存されるため、ファイルや現地の写真を保存し直す必要がありません。`}
          />
          <FeatureItem
            imgSrc="/assets/naiso_kouji/draft-line.svg"
            imgAlt=""
            title={`現場ですぐに\n見積書作成`}
            description="パートナー工事会社様はスマホで見積書を作成し、簡単に提出することができます。"
          />
          <FeatureItem
            imgSrc="/assets/naiso_kouji/smartphone-line.svg"
            imgAlt=""
            title={`面倒な報告書や写真の\n提出もスマホで簡単`}
            description="スマホで撮影した写真を使い、そのままスマホで報告書を作成することができます。"
          />
          <FeatureItem
            imgSrc="/assets/naiso_kouji/pages-line.svg"
            imgAlt=""
            title={`売上・差益を反映した\n精算書の作成`}
            description="パートナー工事会社様から提出された見積書を元に、管理会社の差益を反映させた上で借主・貸主向けの精算書の吐き出しができます。"
          />
          <FeatureItem
            imgSrc="/assets/naiso_kouji/calendar-2-line.svg"
            imgAlt=""
            title={`Web上で進捗確認、\nスムーズに工程管理`}
            description="登録された工事日程をweb上で確認できるので、電話での確認の手間が削減できます。"
          />
        </div>
      </div>
    </section>
    <section className={style.ContactSection}>
      <div className={style.ContactWrapper} id="contact">
        <SectionHeading title="お問い合わせ・資料請求" serviceName="NSK" />
        <ZennichiContactForm />
      </div>
    </section>
  </>
);

export default ZennichiNaisoKouji;
